import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

// Add request interceptor for security headers
axios.interceptors.request.use(config => {
  config.headers['X-Requested-With'] = 'XMLHttpRequest';
  config.headers['Accept'] = 'application/json';
  config.timeout = 10000; // 10 second timeout
  return config;
});

// Add response interceptor for error handling
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 429) {
      console.error('Rate limit exceeded');
    }
    return Promise.reject(error);
  }
);

export const sendMessage = async (text) => {
  try {
    const response = await axios.post(`${API_URL}/message`, {
      text: text
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('API Error:', error.response?.data || error);
    throw new Error(error.response?.data?.detail || `Failed to send message: ${error.message}`);
  }
}; 