import React, { useState } from 'react';
import { sendMessage } from './api';

function App() {
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponse('');
    try {
      if (!message.trim()) {
        setResponse('Message cannot be empty');
        return;
      }
      const data = await sendMessage(message.trim());
      setResponse(data.message);
      setMessage('');
    } catch (error) {
      setResponse(`Error: ${error.message}`);
      console.error('Error:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Cloud App MVP</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter a message"
          />
          <button type="submit">Send</button>
        </form>
        {response && <p>{response}</p>}
      </header>
    </div>
  );
}

export default App; 